<template>
  <div class="downstream">
    <div class="title">下游管理</div>
    <a-row type="flex" justify="space-between" class="function">
      <a-col>
        <span class="text">搜索：</span><a-input-search v-model="form.search" @search="getList" placeholder="请输入企业名称或简称模糊搜索" style="width: 300px" />
      </a-col>
      <a-col>
        <a-button type="primary" @click="visible = true">
          <a-icon type="plus"></a-icon> 添加下游
        </a-button>
      </a-col>
    </a-row>
    <div>
      <a-table rowKey="id" :columns="columns" :dataSource="data" size="middle" :loading="loading" :pagination="{
          size: 'middle',
          total: total,
          current: form.pageNum,
          pageSize: form.pageSize,
          showTotal: total => `共有 ${total} 条数据`,
          showSizeChanger: true,
          showQuickJumper: true,
          onShowSizeChange: (current, pageSize) => {
            form.pageSize = pageSize
            form.pageNum = 1
            this.getList()
          },
          onChange: (pageNum) => {
            form.pageNum = pageNum
            this.getList()
          }
        }">
        <span slot="status" slot-scope="text">{{dsStatusType[text]}}</span>
        <template slot="action" slot-scope="text, record">
            <a-space>
                <a-popconfirm :title="`是否${record.downstreamStatus === 'DISABLE' ? '启用' : '禁用'}?`" @confirm="statusChange(record)">
                    <a>{{record.downstreamStatus === 'DISABLE' ? '启用' : '禁用'}}</a>
                </a-popconfirm>
                <a @click="edit(record)">修改</a>
            </a-space>
        </template>
      </a-table>
    </div>
    
    <a-modal :title="`${isEdit ? '修改' : '添加'}下游企业`" :visible="visible" centered :confirmLoading="confirmLoading" :width="560" @ok="enter" @cancel="cancel">
        <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
            <a-form-item label="企业名称" required>
                <a-input v-model="formDetail.downstreamName"></a-input>
            </a-form-item>
            <a-form-item label="负责人">
                <a-input v-model="formDetail.realName"></a-input>
            </a-form-item>
            <a-form-item label="手机号">
                <a-input v-model="formDetail.phone"></a-input>
            </a-form-item>
            <a-form-item label="税号" required>
                <a-input v-model="formDetail.uniformCode"></a-input>
            </a-form-item>
            <a-form-item label="仓库地址">
                <a-input v-model="formDetail.warehouseAddress"></a-input>
            </a-form-item>
        </a-form>
    </a-modal>
  </div>
</template>
<script>
import {dsList,dsAdd,dsUpdate,dsStatus} from '@/api/buyerCenter'
import {dsStatusType} from '@/utils/constArr'
const columns = [
  {
    title: '下游企业名称',
    dataIndex: 'downstreamName'
  },
  {
    title: '税号',
    dataIndex: 'uniformCode',
  },
  {
    title: '仓库地址',
    dataIndex: 'warehouseAddress',
  },
  {
    title: '状态',
    dataIndex: 'downstreamStatus',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]
export default {
  data() {
    return {
      dsStatusType,
      data: [],
      total: 0,
      columns,
      loading: false,
      confirmLoading: false,
      visible: false,
      isEdit: false,
      form: {
        purchaserId: this.$route.query.entityId,
        search: undefined,
        pageNum: 1,
        pageSize: 10
      },
      formDetail: {
        purchaserId: this.$route.query.entityId,
        downstreamName: '',
        warehouseAddress: '',
        uniformCode: '',
        realName: '',
        phone: ''
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 获取列表
    getList() {
      this.loading = true
      dsList(this.form).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.data = res.data.list
          this.total = res.data.total
        }
      })
    },
    // 状态改变
    statusChange (record) {
      const config = {
        purchaserDownstreamInfoId: record.id,
        purchaserId: this.$route.query.entityId,
        status: record.downstreamStatus === 'ENABLE' ? 'DISABLE' : 'ENABLE'
      }
      dsStatus(config).then(res => {
        if (res.code === 200) {
          this.getList()
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 取消
    cancel () {
      this.visible = false
      this.isEdit = false
      this.formDetail = {
        purchaserId: this.$route.query.entityId,
        downstreamName: '',
        warehouseAddress: '',
        uniformCode: '',
        realName: '',
        phone: ''
      }
    },
    edit (record) {
      this.visible = true
      this.isEdit = true
      const {uniformCode, downstreamName, warehouseAddress, id, managerPhone, managerRealName} = Object.assign({}, record)
      this.formDetail = {uniformCode, downstreamName, warehouseAddress, purchaserId: this.$route.query.entityId, purchaserDownstreamInfoId: id, realName: managerRealName, phone: managerPhone}
    },
    // 确定
    enter () {
      if (!this.formDetail.downstreamName) {
        this.$message.error('请输入企业名称')
        return
      }
      if (!this.formDetail.realName) {
        this.$message.error('请输入负责人')
        return
      }
      if (!this.formDetail.phone) {
        this.$message.error('请输入手机号')
        return
      }
      if (!this.formDetail.uniformCode) {
        this.$message.error('请输入税号')
        return
      }
      (this.isEdit ? dsUpdate(this.formDetail) : dsAdd(this.formDetail)).then(res => {
        if (res.code === 200) {
          this.$message.success(res.message)
          this.getList()
          this.cancel()
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.downstream {
  width: 100%;
  padding: 20px 10px 20px 20px;
  background-color: #ffffff;
  .title {
    padding-left: 10px;
    font-weight: 650;
    font-style: normal;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.847058823529412);
    line-height: 28px;
  }
  .function {
    margin: 20px 0;
    .text {
      font-size: 16px;
    }
  }
}
/deep/ .ant-modal-footer {
    text-align: center;
    padding: 0 0 32px;
    border-top: none;
}
/deep/ .ant-modal-body {
    padding-bottom: 10px;
    .ant-form-item {
        margin-bottom: 16px;
    }
}
</style>