var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"downstream"},[_c('div',{staticClass:"title"},[_vm._v("下游管理")]),_c('a-row',{staticClass:"function",attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',[_c('span',{staticClass:"text"},[_vm._v("搜索：")]),_c('a-input-search',{staticStyle:{"width":"300px"},attrs:{"placeholder":"请输入企业名称或简称模糊搜索"},on:{"search":_vm.getList},model:{value:(_vm.form.search),callback:function ($$v) {_vm.$set(_vm.form, "search", $$v)},expression:"form.search"}})],1),_c('a-col',[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.visible = true}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" 添加下游 ")],1)],1)],1),_c('div',[_c('a-table',{attrs:{"rowKey":"id","columns":_vm.columns,"dataSource":_vm.data,"size":"middle","loading":_vm.loading,"pagination":{
        size: 'middle',
        total: _vm.total,
        current: _vm.form.pageNum,
        pageSize: _vm.form.pageSize,
        showTotal: function (total) { return ("共有 " + total + " 条数据"); },
        showSizeChanger: true,
        showQuickJumper: true,
        onShowSizeChange: function (current, pageSize) {
          _vm.form.pageSize = pageSize
          _vm.form.pageNum = 1
          this$1.getList()
        },
        onChange: function (pageNum) {
          _vm.form.pageNum = pageNum
          this$1.getList()
        }
      }},scopedSlots:_vm._u([{key:"status",fn:function(text){return _c('span',{},[_vm._v(_vm._s(_vm.dsStatusType[text]))])}},{key:"action",fn:function(text, record){return [_c('a-space',[_c('a-popconfirm',{attrs:{"title":("是否" + (record.downstreamStatus === 'DISABLE' ? '启用' : '禁用') + "?")},on:{"confirm":function($event){return _vm.statusChange(record)}}},[_c('a',[_vm._v(_vm._s(record.downstreamStatus === 'DISABLE' ? '启用' : '禁用'))])]),_c('a',{on:{"click":function($event){return _vm.edit(record)}}},[_vm._v("修改")])],1)]}}])})],1),_c('a-modal',{attrs:{"title":((_vm.isEdit ? '修改' : '添加') + "下游企业"),"visible":_vm.visible,"centered":"","confirmLoading":_vm.confirmLoading,"width":560},on:{"ok":_vm.enter,"cancel":_vm.cancel}},[_c('a-form',{attrs:{"label-col":{ span: 4 },"wrapper-col":{ span: 20 }}},[_c('a-form-item',{attrs:{"label":"企业名称","required":""}},[_c('a-input',{model:{value:(_vm.formDetail.downstreamName),callback:function ($$v) {_vm.$set(_vm.formDetail, "downstreamName", $$v)},expression:"formDetail.downstreamName"}})],1),_c('a-form-item',{attrs:{"label":"负责人"}},[_c('a-input',{model:{value:(_vm.formDetail.realName),callback:function ($$v) {_vm.$set(_vm.formDetail, "realName", $$v)},expression:"formDetail.realName"}})],1),_c('a-form-item',{attrs:{"label":"手机号"}},[_c('a-input',{model:{value:(_vm.formDetail.phone),callback:function ($$v) {_vm.$set(_vm.formDetail, "phone", $$v)},expression:"formDetail.phone"}})],1),_c('a-form-item',{attrs:{"label":"税号","required":""}},[_c('a-input',{model:{value:(_vm.formDetail.uniformCode),callback:function ($$v) {_vm.$set(_vm.formDetail, "uniformCode", $$v)},expression:"formDetail.uniformCode"}})],1),_c('a-form-item',{attrs:{"label":"仓库地址"}},[_c('a-input',{model:{value:(_vm.formDetail.warehouseAddress),callback:function ($$v) {_vm.$set(_vm.formDetail, "warehouseAddress", $$v)},expression:"formDetail.warehouseAddress"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }